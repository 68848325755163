<template>
  <div class="login">
    <div class="popup">
      <div>
        <i class="el-icon-loading"></i>
        <p>正在登录...</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    data () {
      return {}
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        if (this.st.user.isLogin) {
          this.$router.push({ name: 'markets' })
        }
        let code = this.getQueryString('code')
        if (code) {
          this.st.user.getToken(code).then(res => {
            if (res) {
              this.getUserInfo()
            } else {
              this.st.user.logout()
            }
          }).catch(() => {
            this.st.user.auth()
          })
        } else {
          this.st.user.auth()
        }
      },
      getUserInfo () {
        this.st.user.getUserInfo().then(res => {
          if (res) {
            this.$store.dispatch('setUserInfo', res)
            let url = window.location.href.split('?')
            window.location.href = `${url[0]}#/onecode/apply`
          }
        }).catch(() => {})
      },
      getQueryString (name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        let r = window.location.search.substr(1).match(reg)
        if (r != null) return unescape(r[2]); return null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup {
    width: 140px;
    height: 140px;
    color: #fff;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 40px;
      margin-bottom: 16px;
    }
  }
</style>
